import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";

const ErrorCode = () => {
    return (
        <Layout hideFooterNavigation={false} hideSubNav={true} title="Something went wrong!">
            <div className="error-page">
                <MainTitle className="error-title">
                    Oops! Something went wrong - Please scan again.
                </MainTitle>
                
                <div className="contact-us">
                    <span>If problem persists, please contact us at:</span>
                    <a className="contact-email" href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
                        {process.env.GATSBY_CONTACT_EMAIL}
                    </a>
                </div>
            </div>
        </Layout>
    );
};
export default ErrorCode;
